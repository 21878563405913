<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <!-- eslint-disable vue/attribute-hyphenation -->
  <!-- eslint-disable vue/html-self-closing -->
  <div
    v-if="!connected"
    class="w-75 mt-4 mx-auto"
  >
    <b-row>
      <b-col cols="6" class="my-auto">
        <div>
          <h2>Kết nối kênh bán hàng Shopee</h2>
          <p class="text-primary">
            Kết nối hệ thống của IFashion và Shopee, giúp đẩy sản phẩm trực tiếp lên Shopee cũng như đồng bộ về tồn kho, đơn hàng.
          </p>
          <p>
            Kênh bán hàng Shopee Shop của bạn chưa được kích hoạt. Vui lòng kích hoạt để bắt đầu sử dụng.
          </p>
          <b-button
            variant="primary"
            class="btn-icon rounded"
            @click="handleConnect"
          >
            {{ $t('Kích hoạt kênh bán hàng') }}
          </b-button>
        </div>
      </b-col>
      <b-col cols="6">
        <img src="/banner_contact_shopee.png" alt="Shopee">
      </b-col>
    </b-row>
  </div>
  <!-- <b-card v-else>
    <b-tabs
      content-class="pt-1"
    >
      <SyncProduct
        :list="syncProductList"
        channel="lazada"
      />
      <NotSyncProduct
        :list="items"
        channel="lazada"
      />
    </b-tabs>
  </b-card> -->
  <b-card
    v-else
    no-body
    class="coupon-card shopee-list"
  >
    <b-card-header>
      <div
        class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
        style="gap: 8px;"
      >
        <div>
          <h4>{{ $t('Shopee sync product list') }}</h4>
        </div>
        <div>
          <!-- <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button> -->
          <strong v-if="productSelected.length > 0">Đã chọn: {{ productSelected.length }}</strong>
          <b-button
            v-b-modal.modal-popup-list
            variant="primary"
            type="button"
            class="ml-2"
            :disabled="productSelected && productSelected.length <= 0 ? true : false"
          >
            <feather-icon
              icon="RefreshCwIcon"
              class="mr-50"
            />
            {{ $t('Sync product') }}
          </b-button>
        </div>
      </div>
      <div class="filter-tab w-100">
        <div
          v-for="item in filterTabs"
          :key="item.id"
          :class="['filter-tab__item', {'active': filterSelected == item.id}]"
          @click="filterSelected = item.id"
        >
          {{ $t(item.label) }}
          <!-- ({{ productCounter(item.id) }}) -->
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <!-- search input -->
      <div class="custom-search d-flex justify-content">
        <b-form-group style="width: 450px;">
          <div class="d-flex align-items-center mr-1">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="modelFilter.name" placeholder="Tìm kiếm theo ID hoặc SKU người bán" />
            </b-input-group>
          </div>
        </b-form-group>
        <b-form-group style="width: 350px;">
          <div class="d-flex align-items-center mr-1">
            <flat-pickr
              v-model="modelFilter.date"
              class="form-control"
              :config="dateRangeConfig"
              :placeholder="$t('From - To date')"
            />
          </div>
        </b-form-group>
        <b-form-group style="width: 350px;">
          <b-button
            variant="primary"
            type="button"
            @click="handleSearch()"
          >
            <feather-icon
              icon="SearchIcon"
              class="mr-50"
            />
            {{ $t('Search') }}
          </b-button>
          <b-button
            variant="outline-primary"
            type="button"
            class="ml-2"
            @click="handleReset"
          >
            <feather-icon
              icon="XIcon"
              class="mr-50"
            />
            {{ $t('Reset') }}
          </b-button>
        </b-form-group>
      </div>

      <vue-good-table
        ref="inventory-receives"
        class="custom-good-table"
        :columns="fields"
        :rows="filterItems"
        styleClass="vgt-table"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="tableSelectOptions"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        @on-selected-rows-change="selectProduct"
      >
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span>
            {{ $t(props.column.label) }}
          </span>
        </template>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Avatar + Name -->
          <span
            v-if="props.column.field === 'id'"
          >
            <!-- <router-link :to="`edit/${props.row.id}`">{{ props.row.code }}</router-link> -->
            {{ props.row.id }}
          </span>

          <span
            v-else-if="props.column.field === 'name'"
            style="max-width: 140px;"
          >
            <div class="d-flex">
              <!-- <avatar-component v-model="props.row" /> -->
              <b-avatar
                :src="props.row.avatar"
                square
                variant="light-primary"
                size="lg"
              />
              <div
                class="d-flex flex-column pl-1"
                style="max-width: 400px;"
              >
                {{ props.row.name }}
                <strong>{{ props.row.totalAttribute }} {{ $t('attributes') }}</strong>
              </div>
            </div>
          </span>

          <span v-else-if="props.column.field === 'category_id'">
            <div>
              {{ props.row.category_id }}
            </div>
          </span>

          <!-- Column: Actions -->
          <div v-else-if="props.column.field === 'is_sync'">
            <b-badge
              :variant="props.row.is_sync ? 'light-success' : 'light-dark'"
              style="max-width: 140px;"
            >
              {{ $t( props.row.is_sync ? 'product_status_synced' : 'product_status_not_sync') }}
            </b-badge>
            <router-link v-if="props.row.product_id" class="d-block mt-1" :to="`/product/edit/${props.row.product_id}`">
              {{ $t('View products') }}
            </router-link>
          </div>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pageOptions"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <!-- <span class="text-nowrap"> of {{ props.total }} entries </span> -->
            </div>
            <div>
              <b-pagination
                v-model="currentPageOriginal"
                :total-rows="totalRows"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-body>
    <b-modal
      id="modal-popup-list"
      hide-footer
      ok-title="Save"
      centered
      size="lg"
      title="Đồng bộ sản phẩm"
      @ok="handlerSubmit"
    >
      <div class="container-ctrl">
        <span v-if="sycnning">Chọn danh mục sản phẩm</span>
        <span v-else>Đồng bộ thành công <strong class="text-success">{{ countDone }}/{{ productSelected.length }} sản phẩm</strong>, <span class="text-danger">{{ countError }} đồng bộ lỗi</span></span>
        <!-- <p>Please choose the right category for your product.</p> -->
        <hr>
        <b-form-group :label="`*`+$t('Category')+`:`" label-cols-md="12" class="align-items-center">
          <b-input-group v-b-modal.modal-xl class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="Edit2Icon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="model.category_name"
              readonly
              :placeholder="$t('Select product category')"
            />
          </b-input-group>
          <!-- <small v-if="categoryIdErrorText !== null" class="text-danger">{{
            categoryIdErrorText
          }}</small> -->
          <popup-add-cate v-model="model" />
        </b-form-group>
        <div>
          <div class="d-flex justify-content-between p-1 bg-gray">
            <div>Sản Phẩm</div>
            <div>Trạng thái</div>
          </div>
          <div
            v-for="(item, index) in productSelected"
            :key="index"
            class="d-flex justify-content-between p-1"
          >
            <div class="d-flex align-items-center pl-1">
              <b-avatar square variant="light-dark" :src="item.avatar" />
              <span class="pl-1">{{ item.name }}</span>
            </div>
            <div class="pl-2">
              <feather-icon v-if="item.loading == 0" icon="MinusIcon" size="24" class="text-primary" />
              <b-spinner v-else-if="item.loading == 1" label="Loading..." />
              <feather-icon
                v-else-if="item.loading == 2"
                icon="CheckIcon"
                size="24"
                class="text-success"
              />
              <feather-icon
                v-else
                v-b-tooltip.hover.top="item.error_text"
                icon="AlertCircleIcon" size="24"
                class="text-warning"
              />
            </div>
          </div>
          <hr />
          <div class="text-right mt-2">
            <b-button
              variant="outline-primary"
              type="button"
              size="sm"
            >
              <!-- <feather-icon
                icon="XIcon"
                class="mr-50"
              /> -->
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              variant="primary"
              type="button"
              class="ml-2"
              size="sm"
              @click="handleSyncProduct"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
              {{ $t('Sync product') }}
            </b-button>
          </div>
        </div>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BButton,
  // BTabs,
  BCard,
  BFormGroup,
  BFormInput,
  BCardBody,
  BCardHeader,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BPagination,
  BAvatar,
  BSpinner,
  BBadge,
  VBTooltip,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import general from '@/mixins/index'
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
// import Request from '@/plugins/service/index'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import NotSyncProduct from './components/NotSyncProduct.vue'
// import SyncProduct from './components/SyncProduct.vue'
import PopupAddCate from './PopupAddCate.vue'
// import AvatarComponent from './components/AvatarComponent.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    // BTabs,
    // NotSyncProduct,
    // SyncProduct,
    BCard,
    BFormGroup,
    BFormInput,
    BCardBody,
    BCardHeader,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BPagination,
    BAvatar,
    BSpinner,
    BBadge,
    VueGoodTable,
    flatPickr,
    PopupAddCate,
    // AvatarComponent,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [general],
  data() {
    return {
      channelDetail: null,
      connected: false,
      isConnect: false,
      items: [],
      syncProductList: [],
      fields: [
        {
          field: 'id',
          label: 'Shopee ID',
          width: '128px',
          sortable: false,
        },
        {
          field: 'name',
          label: 'Product',
          tdClass: 'text-left',
          width: '450px',
          sortable: false,
        },
        {
          field: 'category_id',
          label: 'Category',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },
        {
          field: 'stock',
          label: 'Stock',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'is_sync',
          label: 'Sync Status',
          tdClass: 'text-center',
          sortable: false,
        },
        // {
        //   field: 'actions',
        //   label: 'Tool',
        //   tdClass: 'text-left',
        //   sortable: false,
        // },
      ],
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'Y-m-d',
        maxDate: 'today',
      },
      modelFilter: {},
      perPage: 10,
      pageLength: 10,
      totalRows: 100,
      tempToltalRows: 100,
      currentPageOriginal: 1,
      searchTerm: '',
      pageOptions: [5, 10, 20, 50, 100],
      filterSelected: null,
      filterTabs: [
        {
          id: null,
          label: 'All',
        },
        {
          id: 1,
          label: 'product_status_synced',
        },
        {
          id: 0,
          label: 'product_status_not_sync',
        },
      ],
      productSelected: [],
      model: {
        category_id: null,
        category_name: null,
      },
      lazadaProducts: [],
      tableSelectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      },
      sycnning: true,
      countDone: 0,
      countError: 0,
    }
  },
  computed: {
    filterItems() {
      if (this.filterSelected === null) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalRows = this.tempToltalRows
        return this.items
      }
      if (this.filterSelected === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalRows = this.syncProductList.length
        return this.syncProductList
      // eslint-disable-next-line no-else-return
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.totalRows = this.tempToltalRows
      }
      return this.items.filter(item => item.is_sync === this.filterSelected)
    },
  },
  watch: {
    filterSelected(val) {
      if (val === 0) {
        this.tableSelectOptions.enabled = true
      } else {
        this.tableSelectOptions.enabled = true
      }
    },
    currentPageOriginal() {
      this.loadShopeeProduct()
    },
    pageLength() {
      this.loadShopeeProduct()
    },
    isConnect() {
      this.loadSiteInfo()
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.handlerUpdate(this.$route.query.code, this.$route.query.shop_id)
    }
    this.loadSiteInfo()
    // if (this.filterSelected !== 0) {
    //   this.tableSelectOptions.enabled = false
    // }
  },
  methods: {
    productCounter(key) {
      let result = 0

      if (key === null) {
        result = this.items.length
      } else if (key === 1) {
        result = this.items.filter(item => item.is_sync === 1).length
      } else if (key === 0) {
        result = this.items.filter(item => item.is_sync === 0).length
      }
      return result
    },
    selectProduct(data) {
      this.productSelected = data.selectedRows
    },
    async handleSyncProduct() {
      this.sycnning = false
      this.countDone = 0
      this.countError = 0
      this.productSelected.map(async (item, index) => {
        try {
          this.productSelected[index].loading = 1
          const siteId = JSON.parse(localStorage.getItem('siteID')).id
          // const params = {
          //   site_id: siteId,
          //   item_id: item.id,
          //   category_id: this.model.category_id,
          // }
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/v2/sync_product?site_id=${siteId}&item_id=${item.id}&category_id=${this.model.category_id}`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              this.productSelected[index].loading = 2
              this.countDone += 1
            } else {
              this.productSelected[index].loading = 3
              this.productSelected[index].error_text = String(this.showError(res.data.error, ','))
              this.countError += 1
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
          this.isShow = false
        }
      })
    },
    async loadSiteInfo() {
      try {
        const res = await Request.get(this.$http, `${process.env.VUE_APP_API_URL}/site/${JSON.parse(localStorage.getItem('siteID')).id}?user_id=${JSON.parse(localStorage.getItem('token')).id}`)
        if (res) {
          if (res.data.status) {
            if (res.data.data.token_shopee_v2 !== '' && res.data.data.token_shopee_v2 !== null && res.data.data.token_shopee_v2 !== undefined) {
              this.connected = true
              this.loadSyncProduct()
              this.loadShopeeProduct()
            } else if (this.$route.query.shop_id) {
              await this.handlerUpdate(this.$route.query.code, this.$route.query.shop_id)
            }
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerSubmit(data) {
      if (data.id === 1) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/lazada/auth_partner?mode=sandbox&path=${window.location.origin}/channel/lazada`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 2) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/shopee/auth_partner?mode=production&path=${window.location.origin}/channel/shopee`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.link
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
      if (data.id === 4) {
        try {
          const res = await Request.get(
            this.$http,
            `${process.env.VUE_APP_API_URL}/facebook/get_oauth?type=1&redirect_uri=${window.location.origin}/channel/facebook-live`,
          )
          if (res.status === 200) {
            if (res.data.status) {
              window.location.href = res.data.data.url
            }
          }
        } catch (error) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Opps! Something wrong',
              icon: 'AlertOctagonIcon',
              variant: 'danger',
              text: String(error),
            },
          })
        }
      }
    },
    async handleConnect() {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/v2/auth_partner?path=${window.location.origin}/sales-channel/shopee`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            // window.location.href = res.data.data.link
            window.open(res.data.data.link, 'popup', 'width=1200,height=800')
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handlerUpdate(code, shopID) {
      try {
        const siteID = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/v2/get_access_token?site_id=${siteID}&code=${code}&shop_id=${shopID}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.connected = true
            this.isConnect = true
            this.$router.replace({ query: null })
            window.onunload = window.opener.location.reload()
            window.close()
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    // eslint-disable-next-line consistent-return
    async handleSearch() {
      try {
        if (!this.modelFilter.name) this.loadShopeeProduct()
        if (this.modelFilter.date) {
          const arr = this.modelFilter.date.split(' to ')
          // eslint-disable-next-line camelcase
          const params = {}
          // eslint-disable-next-line prefer-destructuring
          params.date_from = arr[0]
          // eslint-disable-next-line prefer-destructuring
          params.date_to = arr[1]
          this.loadShopeeProduct(params)
          return false
        }
        const siteID = JSON.parse(localStorage.getItem('siteID')).id
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/v2/get_product_detail?site_id=${siteID}&item_id_list=${JSON.stringify([this.modelFilter.name])}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.totalRows = 1
            this.tempToltalRows = 1
            this.items = []
            const findIndex = this.syncProductList.find(x => Number(x.id) === Number(res.data.data[0].item_id))
            if (!findIndex) {
              const item = {
                id: res.data.data[0].item_id,
                name: res.data.data[0].item_name,
                category_id: res.data.data[0].category_id,
                // name: res.data.data.product_name,
                is_sync: 0,
                stock: 0,
                totalAttribute: 0,
              }
              // eslint-disable-next-line array-callback-return, no-unused-expressions
              res.data.data[0].models ? res.data.data[0].models.map(x => {
                item.stock += x.quantity
              }) : false
              // eslint-disable-next-line no-unused-expressions
              res.data.data[0].models ? item.totalAttribute = res.data.data[0].models.length : false
              this.items.push(item)
            } else {
              const item = {
                id: res.data.data[0].item_id,
                name: res.data.data[0].item_name,
                category_id: res.data.data[0].category_id,
                is_sync: 1,
                stock: 0,
                totalAttribute: 0,
              }
              this.items.push(item)
            }
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    handleReset() {
      this.modelFilter.name = null
      this.modelFilter.date = null
      this.loadShopeeProduct()
    },
    async loadShopeeProduct(options) {
      this.isShow = true
      try {
        // await this.handleCategory(siteID)
        const siteID = JSON.parse(localStorage.getItem('siteID')).id
        let params = ''
        if (options) {
          params = `&date_from=${options.date_from}&date_to=${options.date_to}`
        }
        const offset = (this.currentPageOriginal - 1) * this.pageLength
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/shopee/v2/get_product_list?site_id=${siteID}&offset=${offset}&limit=${this.pageLength}&item_status=["NORMAL","UNLIST"]${params}`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.totalRows = res.data.data.total
            this.tempToltalRows = res.data.data.total
            this.items = []
            const tempArrProductId = []
            // eslint-disable-next-line array-callback-return
            res.data.data.list.map(x => {
              tempArrProductId.push(x.product_id)
            })
            const resItem = await Request.get(
              this.$http,
              `${process.env.VUE_APP_API_URL}/shopee/v2/get_product_detail?site_id=${siteID}&item_id_list=${JSON.stringify(tempArrProductId)}`,
            )
            if (resItem.data.status) {
              resItem.data.data.map(async val => {
                const findIndex = this.syncProductList.find(x => Number(x.id) === Number(val.item_id))
                if (!findIndex) {
                  const item = {
                    id: val.item_id,
                    product_id: null,
                    avatar: val.image.image_url_list[0],
                    name: val.item_name,
                    category_id: val.category_id,
                    stock: 0,
                    loading: 0,
                    is_sync: 0,
                    error_text: null,
                    totalAttribute: 0,
                  }
                  // eslint-disable-next-line array-callback-return, no-unused-expressions
                  val.models ? val.models.map(x => {
                    item.stock += x.quantity
                  }) : false
                  // eslint-disable-next-line no-unused-expressions
                  val.models ? item.totalAttribute = val.models.length : false
                  this.items.push(item)
                } else {
                  const item = {
                    id: val.item_id,
                    product_id: findIndex.product_id,
                    avatar: '',
                    name: val.item_name,
                    category_id: val.category_id,
                    // category_name: this.categories.find(x => x.category_id === val.product_category),
                    stock: findIndex.stock,
                    loading: 0,
                    is_sync: 1,
                    error_text: null,
                    totalAttribute: findIndex.totalAttribute,
                  }
                  this.items.push(item)
                }
              })
            }
          } else if (res.data.error) {
            // eslint-disable-next-line eqeqeq
            if (res.data.error.Adapter_error == 'error_auth') {
              this.connected = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Kết nối hết hạn',
                  icon: 'AlertOctagonIcon',
                  variant: 'info',
                  text: 'Kết nối với Shopee Shop của bạn đã hết hạn, vui lòng kết nối lại!',
                },
              })
            }
          }
        }
        this.isShow = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
        this.isShow = false
      }
    },
    async loadSyncProduct() {
      try {
        const siteId = JSON.parse(localStorage.getItem('siteID')).id
        const result = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/products?site_id=${siteId}&is_sync=shopee_id`,
        )
        if (result.status === 200) {
          const res = result.data.data
          this.syncProductList = []
          // eslint-disable-next-line array-callback-return
          res.items.map(val => {
            const temp = {
              id: val.shopee_id,
              product_id: val.id,
              avatar: val.avatar,
              name: val.name,
              category_id: 'null',
              // category_name: this.categories.find(x => x.category_id === val.product_category),
              stock: 0,
              loading: 0,
              is_sync: 1,
              error_text: null,
              totalAttribute: 0,
            }
            // eslint-disable-next-line no-unused-expressions, array-callback-return
            val.warehouse ? val.warehouse.map(x => {
              temp.stock += x.quantity
            }) : false
            // eslint-disable-next-line no-unused-expressions
            val.warehouse ? temp.totalAttribute = val.warehouse.length : false
            this.syncProductList.push(temp)
          })
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
    async handleCategory(siteID) {
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/lazada/get_categories?site_id=${siteID}&language_code=vi_VN`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.categories = res.data.data
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
//   async mounted() {},
//   methods: {},
}
</script>

<style lang="scss" scoped>
// @import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.bg-gray {
  background: #f6f6f6;
  // padding: 12px 0;
}
</style>
<style lang="sass">
.shopee-list
  .filter
    &-tab
      display: flex
      overflow: hidden
      flex: none
      border-bottom: 1px solid #e5e5e5

      &__item
        min-width: 64px
        padding: 0 16px
        height: 56px
        display: flex
        align-items: center
        font-weight: 500
        font-size: 14px
        border-bottom: 3px solid transparent
        cursor: pointer
        transition: all .2s

        &.active,
        &:hover
          color: var(--primary)

        &.active
          border-bottom-color: var(--primary)

  .filter-advance
    display: grid
    gap: 16px

    &__search
      display: grid
      gap: 8px
      grid-template-columns: 1fr repeat(3, 200px)

      .actions
        > div
          display: grid
          align-items: center
          grid-template-columns: repeat(2, 1fr)
          gap: 8px

        .btn
          padding-left: 1rem
          padding-right: 1rem

  table
    font-size: 14px
</style>
